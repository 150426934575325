import React, { useState } from "react"
import { Location } from "@reach/router"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"

import Logo from "./logo.svg"

import Search from "./search"

const searchIndices = [
  { name: `Projects`, title: `Projects` },
  { name: `People`, title: `People` },
]

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Location>
      {({ location }) => (
        <header>
          <div className="pt-6 px-4 sm:px-6 lg:px-8">
            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="/">
                    <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
                  </Link>

                  <div className="-mr-2 flex items-center md:hidden">
                    <button
                      type="button"
                      onClick={handleOpen}
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4">
                <MemoryLink to={"/about"} text="About" first={true} />
                <MemoryLink to={"/themes"} text="Themes" />
                <MemoryLink to={"/projects"} text="Projects" />
                <MemoryLink to={"/cases"} text="Case Studies" />
                {/* <MemoryLink to={"/blog"} text="Updates" /> */}
                <MemoryLink to={"/people"} text="People" />
                <MemoryLink to={"/publications"} text="Impact" />
                <MemoryLink to={"/partners"} text="Partners" />
                {/*<MemoryLink to={"/events"} text="Events" /> */}
                <span className="mr-8"></span>
                <Search indices={searchIndices} />
              </div>
            </nav>
          </div>

          <CSSTransition
            in={open}
            unmountOnExit
            timeout={200}
            classNames="side-menu"
          >
            <div className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md">
                <div className="rounded-lg bg-gray-100 shadow-xs overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <Link to={"/"}>
                      <div>
                        <img className="h-8 w-auto" src={Logo} alt="" />
                      </div>
                    </Link>

                    <div className="-mr-2">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      >
                        <svg
                          className="h-6 w-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3">
                    <SideMenuLink to={"/about"} text="About" first={true} />
                    <SideMenuLink to={"/themes"} text="Themes" />
                    <SideMenuLink to={"/projects"} text="Projects" />
                    <SideMenuLink to={"/cases"} text="Case Studies" />
                    {/* <SideMenuLink to={"/blog"} text="Updates" /> */}
                    <SideMenuLink to={"/people"} text="People" />
                    <SideMenuLink to={"/publications"} text="Impact" />
                    <SideMenuLink to={"/partners"} text="Partners" />
                    { /*<SideMenuLink to={"/events"} text="Events" /> */}
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
          {location.pathname === "/" && <Hero />}
        </header>
      )}
    </Location>
  )
}

export default Header

const MemoryLink = ({ to, text, first = false }) => {
  let baseClasses =
    "font-normal text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out visited:text-purple-600 visited:font-bold"
  if (first === false) {
    baseClasses = `ml-6 ${baseClasses}`
  }
  return (
    <Link
      getProps={({ isPartiallyCurrent }) => {
        return {
          className: isPartiallyCurrent
            ? `text-gray-900 ${baseClasses}`
            : baseClasses,
        }
      }}
      to={to}
    >
      {text}
    </Link>
  )
}

const SideMenuLink = ({ to, text, first = false }) => {
  let baseClasses =
    "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
  if (first === false) {
    baseClasses = `mt-1 ${baseClasses}`
  }
  return (
    <Link to={to} className={baseClasses}>
      {text}
    </Link>
  )
}

const Hero = () => (
  <>
    <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-20">
      <div className="sm:text-center lg:text-left">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          PREMIERE
          <br />
          <span className="text-indigo-600">programme</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          Advances in physics, machine learning, and uncertainty quantification.
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <Link
              to="/publications"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Impact
            </Link>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <Link
              to="/people"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              People
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
)
