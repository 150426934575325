import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Location } from "@reach/router"

import Header from "./header"

const FeatureImage = ({ file }) => (
  <div className="z-0 w-full mt-6 lg:mt-0 lg:w-3/12 lg:absolute lg:inset-y-0 lg:right-0 bg-gray-200  border-gray-600">
    <Img
      className="z-0 object-cover sm:h-72 md:h-96 lg:h-full"
      fluid={file.childImageSharp.fluid}
    />
    <svg
      className="invisible lg:visible z-10 lg:block absolute left-0 inset-y-0 h-full w-64"
      fill="#EDF2F7"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <polygon points="0,0 20,0 0,100" />
    </svg>
  </div>
)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      fileName: file(relativePath: { eq: "home-feature.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Location>
      {({ location }) => (
        <div className="flex flex-col min-h-screen min-w-screen">
          <div className="flex-grow min-w-full">
            <div className="relative max-w-screen-xl mx-auto">
              <div className="relative">
                <Header siteTitle={data.site.siteMetadata.title} />
                {location.pathname === "/" && (
                  <FeatureImage file={data.fileName} />
                )}
                {location.pathname !== "/" && (
                  <main className="mt-10 flex-grow">
                    <div>{children}</div>
                  </main>
                )}
              </div>
            </div>
          </div>
          {location.pathname === "/" && (
            <main className="flex-grow">{children}</main>
          )}
          <Footer />
        </div>
      )}
    </Location>
  )
}

export default Layout

const Footer = () => (
  <footer>
    <div className="flex mt-8 bg-gray-200 border-t border-gray-700">
      <div className="flex flex-col bg-gray-200">
        <div className="text-gray-600 font-bold pt-8 pl-8 pr-8">CONTACT</div>
        <div className="text-gray-600 px-8 pt-4">
          <a
            className="hover:text-gray-900"
            href="https://twitter.com/PREMIERE_UKRI"
          >
            Twitter
          </a>
        </div>
        <div className="text-gray-600 px-8 pt-2 mb-4">
          <a
            className="hover:text-gray-900"
            href="mailto:omatar@imperial.ac.uk"
          >
            Email
          </a>
        </div>
      </div>
    </div>
  </footer>
)
