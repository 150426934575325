import styled from "styled-components"

export default styled.div`
  display: inline;
  position: relative;
  margin: 0.6em 0;
`

// export default styled.div`
//   position: relative;
//   margin: 0.6em 0;
// `
